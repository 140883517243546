//*----------  COMPONENTS  ----------*/
import DashBoard      from '@/components/DashBoard/DashBoard.vue'
import DataGrid       from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
    // ? TabsItems
import TabEvento   from './componentes/TabsItems/tabEvento.vue'
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'
import { mapActions } from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Evento",
    
    components : {
        DashBoard,
        DataGrid,
        TabEvento,
        DialogForm
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        new    : true,
        lazy   : false,
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : false
            },
        },
        mainTab   : null,
        dialog    : false,
        title     : "Nuevo",
        valid     : false,
        users : [],
        user : null,
        actions : [],
        disabled : false,
        errores : {
            Evento : false,
            descripcion : false,
            categoria   : false
        },
        rules : {
            evento : [
                v => !!v || 'Ingrese nombre de la categoría',
            ],
            descripcion : [
                v => !!v || 'Ingrese descripción',
            ],
            categoria : [
                v => !!v || 'Seleccione una categoría',
            ]
        },
        // ********************************
        idCT        : -1,
        evento      : undefined,
        descripcion : undefined,
        fechaU      : null,
        categoria   : { id : 0, name: "", description : "" },
        // :hint="`${categoria.name}`"
        // categoria   : undefined,
        isEval      : 1,
        fullWidth   : true,
        dataSource  : [],
        categorias  : [],
        dtEdit      : null,
        // ?? mostart datos eliminados
        dataFunction : [{
            format : ( data ) => {
                let res = data.val;
                return res;
            },
            class : ( data ) => {
                let res = data.data.data.activo == 1 ? "" : "delete"
                return res;
            }
        }],
        oldObject : {},
        id_baseCtEstatus : 1
    }),
    computed : {

    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Evento",  ["setDataSource"] ),
        // ??? Eventos click de los botones de menu, se pueden reconocer por el idBtn o por siu diRlCA
        loadEvent( {idRlCA, idBtn} ) {
            switch( idBtn ) {
                case "btnNuevo": 
                    this.new    = true;
                    // this.dialog = true;
                    this.setDialog({
                        key    : "dlgEvento",
                        status : true
                    });
                break;
            }
        },
        /* 
        *   Las acciones que se tienen en la tabla principal, se recibe un objeto
        *   event       =>  Datos del renglon donde se puede hacer la actualizacion de los datos que se tienen en ella {event, component, element, row, column} la propiedad row contienen los valores que se pueden cambiar en row.data
        *   dataAction  =>  Objeto que contiene los datos del permiso asignado{ idRlCA, accion, urlIcon, idBtn}, para reconocer el evento se puede reconocer por el idRlCA o por elidBtn
        ??? Estas propiedades se construyen en DistribucionGen.js
        */
        eventActionTable({ event, dataAction }) {
            switch( dataAction.idBtn ) {
                case "btnEdicion"   : this.edit( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnEliminar"  : this.eliminar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnActive"    : this.activar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
            }
        },
        
        getTitle() {
            return this.new ? "Nuevo evento" : "Editar evento";
        },
        // ?? Funciones para la tabla principal
        edit( data ) {
            let dataRow      = data.row.data;
            if(dataRow.activo != 1) {
                this.$Msg.error("Solo se puede editar un evento activo.")
                return;
            }
            this.idCT           = dataRow.id;
            this.categoria  = dataRow.id_soporteCtCategoria;
            // this.categoria   = dataRow.id_soporteCtCategoria;
            this.evento         = dataRow.evento;
            this.isEval         = dataRow.eval == 1;
            this.descripcion    = dataRow.descripcion;
            this.fechaU         = dataRow.fechaU;
            this.disabled       = true;
            this.oldObject      = this.buildObjec();
            this.new            = false;
            // this.dialog = true;
            this.setDialog({
                key    : "dlgEvento",
                status : true
            });
        },
        eliminar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctEvento  = [];
            this.$Msg.confirma(`¿Desea eliminar la categoría ${dataRow.evento}?`).then( result => {
                if( result.value ){
                    ctEvento.push({
                        id_soporteCtEvento : dataRow.id,
                        activo               : -1,
                        updated_at           : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtEvento", "Update", ctEvento, ["id_soporteCtEvento"] ) ) );
                    service.SopU_eliminarEvento( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: "Registro eliminado correctamente" } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        activar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctEvento  = [];
            this.$Msg.confirma(`¿Desea ${dataRow.activo == 1 ? "desactivar" : "activar"} la categoría ${dataRow.evento}?`).then( result => {
                if( result.value ){
                    ctEvento.push({
                        id_soporteCtEvento : dataRow.id,
                        activo               : dataRow.activo == 1 ? 0 : 1,
                        updated_at           : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtEvento", "Update", ctEvento, ["id_soporteCtEvento"] ) ) );
                    service.SopU_activarEvento( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: `Registro ${dataRow.activo == 1 ? "desactivado" : "activado"} correctamente` } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        // ?? Funcion para la tabla de detallado
        eventClose() {
            this.reset();
            this.categoria = { id : 0, name: "", description : "" };
            // this.dialog      = false;
            this.setDialog({
                key    : "dlgEvento",
                status : false
            });
        },
        eventGen() {
            try {
                if( this.validate() ) {
                    if( this.new ) {
                        let evento = this.buildObjec();
                        let arrInfo = [];
                        arrInfo.push(this.$JsTable.getFormat("soporteCtEvento",  "Insert", [ evento ],  ["id_soporteCtEvento"]));
                        service.SopC_evento( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "Evento creado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    } else {
                        let evento = this.buildObjec();
                        let arrInfo = [];
                        let ctEvento = this.$fn.getDiffObj( [this.oldObject],[evento],["id_soporteCtEvento"])
                        if( ctEvento == 0 )
                            throw "No se realizó ningún cambio"
                        ctEvento[0].updated_at = this.fechaU;
                        arrInfo.push(this.$JsTable.getFormat("soporteCtEvento",  "Update", ctEvento,  ["id_soporteCtEvento"]));
                        service.SopU_evento( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "Evento actualizado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    }
                }
            } catch (error) {
                this.$Msg.error( error )
            }
        },
        buildObjec() {
            let obj = {
                id_soporteCtEvento    : this.idCT,
                id_soporteCtCategoria : this.categoria.id,
                // id_soporteCtCategoria : this.categoria,
                isEval                : this.isEval ? 1 : 0,
                evento                : this.evento,
                descripcion           : this.descripcion,
                activo                : 1
            }
            return obj;
        },
        validate() {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        cargarData(  ){
            service.SopR_dataSourceEventos().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    },
    created() {
        service.SopR_dataCategorias().then( result => {
            this.categorias = this.$JsTable.toObj( result.data );
        })
    }
}
